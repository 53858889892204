import React, { useState, useRef, useEffect } from "react"
import Slider from "react-slick"

import iconPrev from "./img/left.svg"
import iconNext from "./img/right.svg"

import style from "./vdp.module.scss"

export default function VDPMainImage(props) {
  const slider = useRef(null)
  
  const { slide, setSlide } = props

  const { ListOfPhotos } = props.vehicle
  const { unavailableImg } = props

  const handleImgError = (event) => {
    event.target.onerror = null;
    event.target.src = unavailableImg
  }

  useEffect(() => {
    (ListOfPhotos.length > 1 ) &&  slider.current.slickGoTo(props.slide)
  }, [props.slide])

  const settings = {
    arrows: false,
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    beforeChange: (old, idx) => {
      props.gotoSlide(idx)
    }
  }

  return (
    <div>
      {(ListOfPhotos.length < 2)
        ?
        (ListOfPhotos.length === 0)
          ?
          <img className={style["main-image"]}
               alt=""
               src={unavailableImg}/>
          :
            <img className={style["main-image"]}
                 alt=""
                 src={ListOfPhotos[0].PhotoUrl}
                 onError={(e) => handleImgError(e)} />
        
      :
        <div style={{ position: "relative"}} className="vdp-update">
          <div className="slider-arrow" style={{ position: "absolute", height: "100%", width: "100%", display: "flex"}}>
          <button
            className={style["slides-preview__prev-btn"]}
            style={{ zIndex: "1", position: "relative", marginTop: "25%", opacity: "0.8", backgroundColor: "#fff0"}}
            onClick={(e) => {
              e.stopPropagation()
              setSlide(slide)
              slider.current.slickPrev()
            }}
          >
            <img className={style["slides-preview__prev-btn-img"]} src={iconPrev} style={{ width: "40px", height: "auto", backgroundColor: "rgb(254 254 254 / 60%)", opacity: "0.4", borderRadius: "50%"}} alt="Previous slide" />
          </button>
          <button
            className={style["slides-preview__prev-btn"]}
            style={{ zIndex: "1", position: "relative", marginTop: "25%", marginLeft: "auto", marginRight: "0", opacity: "0.8", backgroundColor: "#fff0"}}
            onClick={(e) => {
              e.stopPropagation()
              setSlide(slide)
              slider.current.slickNext()
            }}
          >
            <img className={style["slides-preview__prev-btn-img"]} src={iconNext} style={{ width: "40px", height: "auto", backgroundColor: "rgb(254 254 254 / 60%)", opacity: "0.4", borderRadius: "50%"}} alt="Next slide" />
          </button>
          </div>
          <Slider {...settings} ref={slider}>
            {
              ListOfPhotos.map((slide, idx) => (
                  <img className={style["main-image"]}
                       alt=""
                       src={slide.PhotoUrl}
                       onError={(e) => handleImgError(e)} />
              ))
            }
          </Slider>
        </div>    
      }
    </div>
  )
}